import { Form, Input } from 'antd';
import { prop } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InteractiveImages } from '@/components';
import { DropType } from '@/constants';
import { DropZone } from '@/containers/DropZone';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

type Props = {
  blockTitle: string;
  formItemNamePrefix?: string;
  id: number;
  inverted?: boolean;
  media: any;
  mediaCaption: string;
  mediaCaptionMaxLength?: number;
  mediaId: number;
  mediaType?: DropType;
  testId: number;
  updateBlock: any;
};

export const MediaBlock: React.FC<Props> = ({
  blockTitle,
  formItemNamePrefix,
  id,
  testId,
  media,
  mediaCaption,
  mediaCaptionMaxLength,
  mediaId,
  updateBlock,
  mediaType = DropType.Image,
  inverted = false,
}) => {
  const [form] = Form.useForm();

  const { setFieldValue, submit } = form;

  const [count, setCount] = useState(mediaCaption?.length);
  const [mediaStatus, setMediaStatus] = useState(null);

  useEffect(() => {
    if (mediaStatus) {
      submit();
    }
  }, [mediaStatus, submit]);

  const getMediaCaptionValue = useCallback(
    (value: Record<string, string>) => {
      const key = `${formItemNamePrefix}MediaCaption`;

      return prop(key, value) || prop('mediaCaption', value);
    },
    [formItemNamePrefix],
  );

  const onValuesChange = useCallback(
    (value: Record<string, string>) => {
      const changedMediaCaption = getMediaCaptionValue(value);
      setCount(changedMediaCaption?.length);
    },
    [getMediaCaptionValue],
  );

  const handleBackgroundClick = (backgroundId: number) => {
    setFieldValue(formItemNamePrefix ? `${formItemNamePrefix}MediaId` : 'mediaId', backgroundId);
    submit();
  };

  return (
    <FormBlock
      testId={id}
      blockTitle={blockTitle}
      form={form}
      updateBlock={updateBlock}
      maxCount={mediaCaptionMaxLength}
      count={count}
      onValuesChange={onValuesChange}
      inverted={inverted}
      initialValues={{
        mediaCaption,
        mediaId,
        [`${formItemNamePrefix}MediaId`]: mediaId,
        [`${formItemNamePrefix}MediaCaption`]: mediaCaption,
      }}
    >
      <Form.Item
        name={formItemNamePrefix ? `${formItemNamePrefix}MediaId` : 'mediaId'}
        getValueFromEvent={(value) => {
          // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
          value?.status && setMediaStatus(value?.status);

          return value?.id ?? null;
        }}
        getValueProps={() => ({
          value: media,
        })}
      >
        <DropZone type={mediaType} />
      </Form.Item>
      <Form.Item
        name={formItemNamePrefix ? `${formItemNamePrefix}MediaCaption` : 'mediaCaption'}
        className={styles.inputRow}
      >
        <Input
          className={styles.input}
          rootClassName={styles.inputRoot}
          placeholder="Подпись к изображению"
          maxLength={mediaCaptionMaxLength}
        />
      </Form.Item>

      <InteractiveImages
        onSelectImage={handleBackgroundClick}
        selectedImageId={mediaId}
        testId={testId}
      />
    </FormBlock>
  );
};
