import { FileResponsePrivate } from '@tests/types';
import { Col, notification, Row, Space } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, ifElse, isEmpty, multiply } from 'ramda';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';

import { Delete } from '@/assets';
import { DropType, MEGABYTE, SECOND } from '@/constants';
import { Preview } from '@/containers/DropZone/Preview';
import { ConfigContext } from '@/providers';
import {
  useCreateUploadFileMutation,
  useGetFileByIdMutation,
  useUploadFileMutation,
} from '@/services';
import { getArrayBuffer, isDefined, isSuccessResult } from '@/utils';

import styles from './styles.module.scss';
import {
  getAccept,
  getErrorMessage,
  getFileName,
  isComplete,
  isConverting,
  isError,
  isInProgress,
} from './utils';

interface Props extends DropzoneOptions {
  className?: string;
  onChange?(file?: FileResponsePrivate): void;
  previewText?: string;
  size?: string;
  type: DropType;
  updateFileStatus?(file: FileResponsePrivate): Promise<FileResponsePrivate>;
  uploadProps?: { id: number } & Record<string, string | number>;
  validErrorMessage?: string;
  value?: FileResponsePrivate;
  wrapperClassName?: string;
}

/**
 * onDrop -> onUploadFile -> createUploadFile -> uploadFile
 */
export const DropZone: React.FC<Props> = (props) => {
  const {
    className,
    maxFiles = 1,
    maxSize,
    onChange,
    previewText,
    size = 'big',
    type: dropType,
    updateFileStatus,
    uploadProps,
    validErrorMessage,
    value,
    wrapperClassName,
    ...dropProps
  } = props;

  const {
    upload: { maxSizeImage, timeFileCheck },
  } = useContext(ConfigContext);
  const defaultCheckStatus = multiply(timeFileCheck, SECOND);
  const getMaxSize = cond<[DropType], number>([
    [equals(DropType.Image), always(multiply(maxSizeImage, MEGABYTE))],
    [equals(DropType.Gif), always(multiply(maxSizeImage, MEGABYTE))],
    [equals(DropType.Video), always(multiply(maxSizeImage, MEGABYTE * 10))],
    [equals(DropType.All), always(multiply(maxSizeImage, MEGABYTE * 2))],
  ]);

  const maxSizeFile = maxSize || getMaxSize(dropType);

  const [errorLoading, setErrorLoading] = useState(false);
  const [file, setFile] = useState(value);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (value && value.id) {
      setFile(value);
    }
  }, [value, value?.id]);

  const onUploadProgress = (progressEvent: ProgressEvent) =>
    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));

  const accept = useMemo(() => getAccept(dropType), [dropType]);

  const [createUploadFile] = useCreateUploadFileMutation();
  const [uploadFile] = useUploadFileMutation();
  const [getFileById] = useGetFileByIdMutation();

  const isSmall = ifElse(equals('small'), always(true), always(false));

  const onUploadFile = useCallback(
    async (file: File | Blob) => {
      try {
        setProgress(0);
        setErrorLoading(false);
        const { size: contentLength, type: contentType } = file;
        const createUploadResult = await createUploadFile({
          contentLength,
          contentType,
        });

        if (!isSuccessResult(createUploadResult)) {
          throw createUploadResult.error;
        }

        const {
          data: { fileId, uploadUrl },
        } = createUploadResult;

        const data = await getArrayBuffer(file);

        const uploadResult = await uploadFile({
          ...uploadProps,
          contentType,
          data,
          onUploadProgress,
          url: uploadUrl,
        });

        if (!isSuccessResult(uploadResult)) {
          throw uploadResult.error;
        }

        const fileResponse = await getFileById(fileId);

        if (!isSuccessResult(fileResponse)) {
          throw fileResponse.error;
        }

        setFile(fileResponse?.data);
        onChange(fileResponse?.data);
      } catch (e: any) {
        notification.error({
          description: JSON.stringify(e?.message || ''),
          message: `Ошибка загрузки ${e?.code || ''}`,
        });
      } finally {
        setProgress(0);
      }
    },
    [createUploadFile, getFileById, onChange, uploadFile, uploadProps],
  );

  const onDrop = useCallback(
    // eslint-disable-next-line consistent-return
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (!isEmpty(fileRejections)) {
        fileRejections.forEach((file) => {
          notification.error({
            description: getErrorMessage(file),
            message: (
              <div style={{ display: 'grid' }}>
                <span>Ошибка загрузки файла</span>
                <span>{getFileName(file)}</span>
              </div>
            ),
            placement: 'bottomRight',
          });
        });
      }

      if (isEmpty(acceptedFiles)) {
        return false;
      }

      setProgress(0);

      // const type = acceptedFiles[0].type.split('/')[0];
      //
      // if (accept?.indexOf(type) === -1) return;
      const [file] = acceptedFiles;
      onUploadFile(file);
    },
    [onUploadFile],
  );

  const { getInputProps, getRootProps, rootRef } = useDropzone({
    accept,
    maxFiles,
    maxSize: maxSizeFile,
    noDragEventsBubbling: true,
    onDrop,
    ...dropProps,
  });

  const onDeleteFile: React.MouseEventHandler<HTMLElement> = useCallback(
    async (event) => {
      setFile(undefined);
      setErrorLoading(false);
      if (isDefined(onChange)) {
        onChange(undefined);
      }
      rootRef.current?.focus();
      event.preventDefault();
    },
    [onChange, rootRef],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setErrorLoading(isError(file));
    if (isInProgress(file) || isConverting(file)) {
      let timerId = setTimeout(async function update() {
        const checkedFile = await getFileById(file.id);
        if ('data' in checkedFile) {
          if (isInProgress(checkedFile?.data) || isConverting(checkedFile?.data)) {
            timerId = setTimeout(update, defaultCheckStatus);
            if (isConverting(checkedFile?.data) && checkedFile?.data?.variants.length > 0) {
              setFile(checkedFile?.data?.variants[0]);
              onChange(checkedFile?.data?.variants[0]);
            }
          } else if (isComplete(checkedFile?.data)) {
            setFile(checkedFile?.data);
            onChange(checkedFile?.data);
          }
        }
      }, defaultCheckStatus);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [file, file?.status, file?.id]);

  const DropZonePreview = useCallback(() => {
    if (file && isComplete(file)) {
      return (
        <div
          className={classNames(
            styles.previewWrapper,
            styles[wrapperClassName],
            isSmall(size) && styles.small,
          )}
        >
          {file.type === 'image' && <img alt="" src={file.url} />}
          {file.type === 'video' && (
            <ReactPlayer url={file.url} controls width="100%" height="100%" />
          )}
        </div>
      );
    }

    return (
      <Preview
        passedPreviewText={previewText}
        type={dropType}
        isError={errorLoading}
        isProcessing={isDefined(file) && isInProgress(file)}
        isConverting={isDefined(file) && isConverting(file)}
        isUploading={progress > 0}
        uploadProgress={progress}
        size={size}
        validErrorMessage={validErrorMessage}
        className={wrapperClassName}
      />
    );
  }, [className, dropType, errorLoading, file, progress, value, validErrorMessage, file?.id]);

  return (
    <Row justify="center">
      {/* TODO переписать дропзону на разные типы диза */}
      <Col span={isSmall(size) || wrapperClassName === 'projectLogo' ? 24 : 'auto'}>
        <div
          className={classNames(
            styles.wrapper,
            styles[wrapperClassName],
            isSmall(size) && styles.small,
          )}
        >
          <div style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }}>
            {isDefined(file) && isComplete(file) && !errorLoading && (
              <Space size={12}>
                <div onClick={onDeleteFile} className={styles.delete}>
                  <Delete />
                </div>
              </Space>
            )}
          </div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Row>
              <Col span={24}>
                <DropZonePreview />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};
